<template lang="pug">
  .car-model-table
    table
      thead
        tr
          th.car-model-code.sortable(@click="handleSorting('code')")
            span {{ $t("company_system.car_class_settings.car_model_code") }}
            FaIcon.icon(:icon="sortingIcon('code')")
          th.car-model-name.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.car_class_settings.car_model_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.car-manufacturer {{ $t("company_system.car_class_settings.car_manufacturer") }}
          th.last-updated.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.car_class_settings.last_updated") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            :key="item.id"
            @click="$emit('select-car', item)"
          )
            td {{ item.code }}
            td {{ item.name }}
            td {{ extractTranslatedManufacturerNames(item) }}
            td {{ item.last_updated }}
            td
              AppIconButton.remove-action(
                useAppIcon
                icon="trash"
                @click.stop="$emit('remove-car', item)"
              )
</template>

<script>
  // misc
  import { extractTranslatedAttribute } from "@/helpers/common"

  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },

      sortingData: Object
    },

    methods: {
      extractTranslatedManufacturerNames(item) {
        return extractTranslatedAttribute(item, "manufacturer_names")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .car-model-table
    +listing-container(124px)
    margin-top: 30px

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.car-model-code,
          &.last-updated
            width: 16%

          &.car-model-name,
          &.car-manufacturer
            width: 32%

          &.actions
            width: 4%
      tbody
        tr
          cursor: pointer

          &:hover
            box-shadow: 0 0 3px 0 $default-gray

          td
            .remove-action
              +icon-button($default-purple)
</style>
